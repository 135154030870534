import React from "react";
import { FormattedMessage } from "../../util/reactIntl";
import moment from "moment";
import css from "./ListingPage.module.css";

const SectionListingInfo = ({ publicData, listingConfig }) => {
  if (!publicData || !listingConfig) return null;

  const {
    eventDate,
    eventName,
    searchTermsAndHashtags,
    seatNumbersIfAny,
    howManyTicketsAreSoldWithThisListing,
    headlinersAndArtists,
    musicGenreS,
    ticketType
  } = publicData;

  const dateBox = eventDate ? moment(eventDate).format("L") : null;
  const tagsArry = typeof searchTermsAndHashtags === "string"
    ? searchTermsAndHashtags.split(" ").map(term => term.replace("#", ""))
    : searchTermsAndHashtags || [];

  // Helper function to render details
  const renderDetail = (labelId, value) => (
    value ? (
      <div className={css.eventDetail}>
        <span className={css.boldText}>
          <FormattedMessage id={labelId} />
        </span>
        <span className={css.eventData}>{value}</span>
      </div>
    ) : null
  );

  return (
    <div className={css.orderBoxWrapper}>
      {renderDetail("SectionListingInfo.eventName", eventName)}
      {renderDetail("SectionListingInfo.eventStart", dateBox)}
      {renderDetail("SectionListingInfo.seatsNumber", seatNumbersIfAny)}
      {renderDetail("SectionListingInfo.ticketSold", howManyTicketsAreSoldWithThisListing)}
      {renderDetail("SectionListingInfo.headliners", headlinersAndArtists)}
    
      {Array.isArray(musicGenreS) && musicGenreS?.length > 0 && (
        <div className={css.eventDetail}>
          <span className={css.boldText}>
            <FormattedMessage id="SectionListingInfo.music" />
          </span>
          <div className={css.tagsBox}>
            {musicGenreS.map((genre, i) => (
              <span key={i} className={css.hashTags}>{genre}</span>
            ))}
          </div>
        </div>
      )}
      {renderDetail("SectionListingInfo.ticketType", ticketType)}

      {Array.isArray(tagsArry) && tagsArry.length > 0 && (
        <div className={css.eventDetail}>
          <span className={css.boldText}>
            <FormattedMessage id="SectionListingInfo.searchTerms" />
          </span>
          <div className={css.tagsBox}>
            {tagsArry.map((tag, i) => (
              <span key={i} className={css.hashTags}>{tag}</span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionListingInfo;
